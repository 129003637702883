import * as React from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/Seo'

const SupportPage = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="Support Me"
        description="Have a look at ways to support Geoffrey Hunt."
      />

      <div className="bg-light pt-8 pb-5 pr-5 pl-5 md:pr-10 md:pl-10 mt-4 mb-4 max-w-full rounded-md prose">
        <h1>How to support me</h1>
        <p>
          I got asked by a friend "how can I support you mate?". So here are
          some options, thanks if you do any of these!
        </p>
        <ul>
          <li className="text-lg pb-1">
            Star some of my repositories on Github{' '}
            <a
              href="https://github.com/runningdeveloper"
              target="_blank"
              rel="noreferrer"
              className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light"
            >
              https://github.com/runningdeveloper
            </a>
          </li>
          <li className="text-lg pb-1">
            Follow me on Twitter{' '}
            <a
              href="https://twitter.com/geoff4l"
              target="_blank"
              rel="noreferrer"
              className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light"
            >
              https://twitter.com/geoff4l
            </a>
          </li>
          <li className="text-lg pb-1">
            Watch and like my YouTube videos{' '}
            <a
              href="https://www.youtube.com/geoff4l"
              target="_blank"
              rel="noreferrer"
              className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light"
            >
              https://www.youtube.com/geoff4l
            </a>
          </li>
          {/* <li className="text-lg pb-1">
            Get some laser cutting done at Create15 - one of my side
            projects at{' '}
            <a
              href="https://create15.com"
              target="_blank"
              rel="noreferrer"
              className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light"
            >
              https://create15.com
            </a>{' '} (Website is still a WIP)
          </li> */}
          {/* <li className="text-lg pb-1">
            Buy a solar system or solar pump from one of my good clients Cedar
            Solar{' '}
            <a
              href="https://www.cedarsolar.com/"
              target="_blank"
              rel="noreferrer"
              className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light"
            >
              https://www.cedarsolar.com
            </a>
          </li> */}
          <li className="text-lg pb-1">
            Say hello and invite me to a weird race{' '}
            <Link
              to="/contact/"
              className="underline text-bright transition duration-500 ease-in-out transform hover:text-dark hover:bg-light"
            >
              contact page
            </Link>
          </li>
        </ul>

        <div className="mt-8">
          <iframe
            src="https://giphy.com/embed/KJ1f5iTl4Oo7u"
            frameBorder="0"
          ></iframe>
        </div>
      </div>
    </Layout>
  )
}

export default SupportPage

export const query = graphql`
  query {
    site {
      siteMetadata {
        description
        title
      }
    }
    # file(relativePath: { eq: "shoe1.png" }) {
    #   childImageSharp {
    #     # Specify the image processing specifications right in the query.
    #     fixed(width: 200) {
    #       ...GatsbyImageSharpFixed
    #     }
    #   }
    # }
    # allMdx(sort: { order: DESC, fields: frontmatter___title }) {
    #   edges {
    #     node {
    #       frontmatter {
    #         title
    #       }
    #       id
    #       slug
    #       excerpt(truncate: true)
    #       body
    #     }
    #   }
    # }
  }
`
